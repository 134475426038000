import {React, useState} from 'react';
import Header from './header/header';
import PersData from './persdata/persdata';
import SPData from './spdata/spdata';
import HeaderMobile from './header/headerMobile';
import './lk.scss'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie/es6';

export default function LK() {

  const [mobilePersDataBlock, setMobilePersDataBlock] = useState(false);
  
  const navigate = useNavigate();
  const logOut = () =>{
    const cookies = new Cookies();
    cookies.remove('idik');
    navigate("/auth");
  }

  return <div id='LK'>
    <HeaderMobile mobilePersDataBlock={mobilePersDataBlock} setMobilePersDataBlock={setMobilePersDataBlock} logOut={logOut}/>
    <Header logOut={logOut}/>
    <div id='LKContentBlock'>
      <div id='LKContent'>
        <PersData mobilePersDataBlock={mobilePersDataBlock} />
        <SPData />
      </div>
    </div>
  </div>;
}
