import React from 'react';
import './header.scss'
import logo from './icons/LogoSVG.svg'
import logout from './icons/logout.png'

export default function Header({logOut}) {
  return <div id="Header" className='pcBlock'>
      <img id='HeaderLogo' src={logo}/>
      <div id='HeaderRightBlock'>
        <div>
          <div id='HeaderRightPhone'>8 800 775 20 24</div>
          <div id='HeaderRightText'>Бесплатные звонки по России</div>
        </div>
        <img id='HeaderLogoOut' onClick={()=> logOut()} src={logout}/>
      </div>
  </div>;
}
