import { Route, Routes, Outlet, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import './App.scss';
import Auth from './components/auth/auth';
import LK from './components/lk/lk';
import axios from 'axios';
import SetLogIn from './components/lk/setlogin';

function App() {

  const cookies = new Cookies();

  document.title = "Электронная иммунокарта"
  document.documentElement.lang = 'ru'

  if (cookies.get("url") === undefined)
  fetch("/customData.json").then(response => response.json()).then(resp => {cookies.set('url', resp.url, { path: '/', expires: new Date(Date.now() + 315360000000) }); console.log(resp.url)});

  const PrivateWrapper = () => {
    var tokenCookie = cookies.get("idik")
    return tokenCookie != null ? <Outlet /> : <Navigate to="/auth" />;
  };

  axios.interceptors.request.use(function (config) {
    config.baseURL = "https://api.imkart.ru/eimkart";
    config.headers = { 'ApiKeyEImkart':'e7X7zwFqutbKIm4Z' }
    //config.baseURL = "https://localhost:5001/eimkart";
    //config.baseURL = cookies.get(['url']);
    return config;
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/auth" element={<Auth/>}/>
        <Route end path="/setlogin/:idik" element={<SetLogIn/>}/>
        <Route element={<PrivateWrapper />}>
          <Route path="/" element={<LK/>} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
