import { React, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useNavigate, useParams } from "react-router-dom";

export default function SetLogIn() {
    let { idik } = useParams();
    const cookies = new Cookies();
    const navigate = new useNavigate();
    useEffect(() => {
        cookies.set('idik', idik, { path: '/' });
        navigate("/");
    }, [])

    return <div></div>;
}
