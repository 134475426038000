import { React, useState } from 'react';
import './auth.scss'
import logowhitewithtext from './icons/logowhitewithtext.png'
import crosscircle from './icons/crosscircle.svg'
import logoasco from './icons/logoasco.png'
import Cleave from 'cleave.js/react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';
import InputMask from "react-input-mask";
export default function Auth() {

  const cookies = new Cookies();
  const history = useNavigate();

  const [data, setData] = useState({ NumberIK: "", Family: "", DateB: "" });
  const [inValid, setInValid] = useState({ NumberIK: false, Family: false, DateB: false, NotFoundIK: false })

  const auth = () => {
    if (data.NumberIK.trim() === "" || data.Family.trim() === "" || data.DateB.trim() === "") {
      if (data.NumberIK.trim() === "")
        inValid.NumberIK = true;
      if (data.Family.trim() === "")
        inValid.Family = true;
      if (data.DateB.trim() === "")
        inValid.DateB = true;
      setInValid({ ...inValid })
      return;
    }
    axios.post("/getidimkart", data).then(response => { cookies.set('idik', response.data, { path: '/' }); history("/") }).catch(() => setInValid({ ...inValid, NotFoundIK: true }))
  }
  const enterPress = (event) => {
    if (event.key === 'Enter') {
      auth()
    }
  }

  return <div id="Auth">
    <div id="AuthBannerBlock" className='pcBlock'>
      <img id="AuthLogoWhite" src={logowhitewithtext} alt='' />
      <div className='authBannerText'>
        <div id='AuthBannerMiddleTextTitle'>Возможности личного кабинета</div>
        <div>
          <div className='authBannerMiddleText'>-Скачать электронную иммунокарту</div>
          <div className='authBannerMiddleText'>-Посмотреть перечень доступных услуг по вашей страховой программе</div>
          <div className='authBannerMiddleText'>-Узнать, где можно получить медицинскую помощь</div>
        </div>
      </div>
      <div className='authBannerText'>
        <div id='authBannerDownTextPhone'>8 800 775 20 24</div>
        <div id='authBannerDownText'>Бесплатные звонки по России</div>
      </div>
    </div>
    <div id="AuthFormBlock">
      <div id="AuthForm">
        <div className='authFormBlock'>
          <img id="AuthLogo" src={logoasco} alt='' />
          <div id='AuthLogoText'>Вход в личный кабинет</div>
        </div>
        <div className='authFormBlock' id='AuthFormBlock'>
          <Cleave onKeyPress={e => enterPress(e)} value={data.NumberIK} onChange={e => { setData({ ...data, NumberIK: e.target.value }); setInValid({ ...inValid, NumberIK: false }) }} placeholder={inValid.NumberIK ? "Введите № иммунокарты" : "№ Иммунокарты"} className={'inputCustom authFormInput' + (inValid.NumberIK ? " inValid" : "")} type="text" options={{
            numericOnly: true,
            blocks: [7]
          }} />
          <input onKeyPress={e => enterPress(e)} value={data.Family} onChange={e => { setData({ ...data, Family: e.target.value }); setInValid({ ...inValid, Family: false }) }} className={'inputCustom authFormInput' + (inValid.Family ? " inValid" : "")} placeholder={inValid.Family ? "Введите фамилию" : "Фамилия"} type="text" />
          {/* <Cleave onKeyPress={e => enterPress(e)} value={data.DateB} onChange={e => { setData({ ...data, DateB: e.target.value }); setInValid({ ...inValid, DateB: false }) }} placeholder={inValid.DateB ? "Введите дату рождения (дд.мм.гггг)" : "Дата рождения (дд.мм.гггг)"} className={'inputCustom authFormInput' + (inValid.DateB ? " inValid" : "")} type="text" options={{ date: true, delimiter: '.', delimiterLazyShow: false, datePattern: ['d', 'm', 'Y'] }} /> */}
          <InputMask
            mask="99.99.9999"
            onKeyPress={e => enterPress(e)}
            onChange={e => {
              setData({ ...data, DateB: e.target.value });
              setInValid({ ...inValid, DateB: false })
            }}
            placeholder={inValid.DateB ? "Введите дату рождения (дд.мм.гггг)" : "Дата рождения (дд.мм.гггг)"}
            className={'inputCustom authFormInput' + (inValid.DateB ? " inValid" : "")}
            value={data.DateB}
          />
          <div id='AuthNotFoundIKMobileBlock' className={"mobileBlock" + (inValid.NotFoundIK ? "" : " hidden")}>
            <div id='AuthNotFoundIKMobile'>Иммунокарта не найдена</div>
          </div>
        </div>
        <div id='AuthButton' onClick={() => auth()}>Вход</div>

        <div id='AuthNotFoundIK' className={"pcBlock" + (inValid.NotFoundIK ? "" : " hidden")}>
          <div id='AuthNotFoundIKHeaderBlock'>
            <img id='AuthNotFoundIKHeaderCrossIcon' src={crosscircle} alt='' />
            <div id='AuthNotFoundIKHeaderText'>Иммунокарта не найдена</div>
          </div>
          <div className='grayText authNotFoundIKTextBlock'>
            Проверьте правильность указанных данных
            и повторите попытку.
            <br />Если данные введены верно, обратитесь по телефону горячей линии для получения помощи
          </div>
          <div className='grayText authNotFoundIKTextBlock'>
            <div id='AuthNotFoundIKPhone'>8 800 775 20 24</div>
            <div>звонок по России бесплатный</div>
          </div>
        </div>
        <div id="AuthPhoneMobileBlock" className='mobileBlock'>
          <div id="AuthPhoneMobilePhone">8 800 775 20 24</div>
          <div id="AuthPhoneMobileText">Бесплатный звонок по России</div>
        </div>
      </div>
    </div>
  </div>;
}
