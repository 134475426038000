import { React, useState, useEffect } from 'react';
import './persdata.scss'
import Phone from './icons/Phone.png'
import Document from './icons/Document.png'
import Calendar from './icons/Calendar.png'
import Hospital from './icons/Hospital.png'
import CheckTab from './icons/CheckTab.png'
import Card from './icons/Card.png'
import Building from './icons/Building.png'
import Building2 from './icons/Building2.png'
import axios from 'axios';
import Cookies from 'universal-cookie';


export default function PersData({ mobilePersDataBlock }) {

  const cookies = new Cookies();

  const [data, setData] = useState({ fullName: "-", dateB: "-", phone: "-", ik: "-", periodIK: "-", regionSale: "-", status: "-", policy: "-", periodPolicy: "-", regionIC: "-", ic: "-", });

  useEffect(() => {
     axios.get("/getdataik/" + cookies.get(['idik'])).then(response => setData(response.data));
  }, [])

  return <div id='PersData' className={mobilePersDataBlock ? "Open" : ""}>
    <div id='PersDataNameBlock'>
      <div id='PersDataName'>{data.fullName}</div>
      <div id='PersDataDateB'>{data.dateB}</div>
    </div>
    <div id='PersDataPhoneBlock'>
      <div id='PersDataPhoneContentBlock'>
        <img className='persDataIcons' src={Phone} alt='' />
        <div>
          <div className='blackText'>Телефон</div>
          <div className='grayText persDataGrayText'>{data.phone}</div>
        </div>
      </div>
      <div></div>
    </div>

    <div className='persDataListItemsBlock'>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Document} alt='' />
        <div>
          <div className='blackText'>Иммунокарта</div>
          <div className='grayText persDataGrayText'>{data.ik}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Calendar} alt='' />
        <div>
          <div className='blackText'>Срок действия ИК</div>
          <div className='grayText persDataGrayText'>{data.periodIK}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Hospital} alt='' />
        <div>
          <div className='blackText'>Регион продажи</div>
          <div className='grayText persDataGrayText'>{data.regionSale}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={CheckTab} alt='' />
        <div>
          <div className='blackText'>Статус</div>
          {
            data.status === "Активна" ?
              <div className='grayText persDataGrayText'>{data.status}</div>
              : <div className='grayText persDataGrayText' id="PersDataDontActiveText">{data.status}</div>
          }
        </div>
      </div>
      {
        data.status === "Активна" || data.status === "Неактивна" ? null
          : <div className='persDataContentBlock' id="PersDataDontActiveBlock">
            Иммунокарта начнет своё действие с {data.dateStart}г.
          </div>
      }
    </div>

    <div className='persDataListItemsBlock'>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Card} alt='' />
        <div>
          <div className='blackText'>Номер полиса</div>
          <div className='grayText persDataGrayText'>{data.policy}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Calendar} alt='' />
        <div>
          <div className='blackText'>Срок действаия полиса</div>
          <div className='grayText persDataGrayText'>{data.periodPolicy}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Building} alt='' />
        <div>
          <div className='blackText'>Регион страховой компании</div>
          <div className='grayText persDataGrayText'>{data.regionIC}</div>
        </div>
      </div>
      <div className='persDataContentBlock'>
        <img className='persDataIcons' src={Building2} alt='' />
        <div>
          <div className='blackText'>Страховая компания</div>
          <div className='grayText persDataGrayText'>{data.ic}</div>
        </div>
      </div>
    </div>

  </div>;
}
