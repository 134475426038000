import { React, useState, useEffect } from 'react';
import './spdata.scss'
import DontActiveIK from './icons/DontActiveIK.svg'
import DownloadIK from "./icons/DownloadIK.png"
import Antibiotics from "./icons/Antibiotics.png"
import GetBlood from "./icons/GetBlood.png"
import DiscoveredBlood from "./icons/DiscoveredBlood.png"
import Doctor from "./icons/Doctor.png"
import Seroprofialctic from "./icons/Seroprofialctic.png"
import Mite from "./icons/Mite.png"
import Check from "./icons/Check.png"
import Cookies from 'universal-cookie/es6';
import axios from 'axios';

export default function SPData() {

  const cookies = new Cookies();

  const [data, setData] = useState([]);

  let icons = {};
  icons["antibiotikoprofilactic"] = Antibiotics;
  icons["getBlood"] = GetBlood;
  icons["discoveredBlood"] = DiscoveredBlood;
  icons["doctor"] = Doctor;
  icons["seroprofilactik"] = Seroprofialctic;
  icons["discoveredMite"] = Mite;

  useEffect(() => {
    axios.get('/getdatasp/' + cookies.get(['idik'])).then(response => setData(response.data));
  }, [])

  return <div id='SPData'>
    {
      data.active
        ? <div id='SPDataButtonBlock'>
          {
            data.activeButton
              ? <a id='SPDataDownloadIK' href={"https://api.imkart.ru/eimkart/getpdf/" + cookies.get(['idik'])} target='_blank' rel='noopener noreferrer'>
                <div id='SPDataDownloadIKText'>Скачать иммунокарту</div>
                <img id='SPDataDownloadIKIcon' src={DownloadIK} />
              </a>
              :
              <div id='SPDataDownloadIK' className='Disable'>
                <div id='SPDataDownloadIKText'>Скачать иммунокарту</div>
                <img id='SPDataDownloadIKIcon' src={DownloadIK} />
              </div>
          }

          <div id='SPDataHelpButtonBlock'>
            <a className='spDataHelpButton' href="https://imkart.ru/documents/chto-delat-pri-ukuse-kleshcha/" target='_blank'>
              Где получить помощь ?
            </a>
            <a className='spDataHelpButton' href="https://api.imkart.ru/Список пунктов оказания помощи.xlsx" target='_blank'>
              Список пунктов оказания помощи
            </a>
          </div>
        </div>
        : null
    }
    {
      data.active
        ? <div id='SPDataSPBlock'>
          <div id='SPDataSPTitle'>Страховая программа</div>
          <div id='SPDataSPContent'>

            {
              data.items.map((item, index) =>
                <div key={"spitem_" + index} className='spataSPItemBlock'>

                  <img src={icons[item.icon]} className='spdataSPIcons' />
                  <div className='spdataSPItemParametersBlock'>

                    <div className='spdataSPItemParameterBlock'>
                      <div>
                        <div className='blackText'>{item.title}</div>
                        <div className='spdataChildrenServicesBlock'>
                          {
                            item.childrenService.map((cs, index) =>
                              <div key={"spitemchild_" + index} className='grayText spdataChildrenService'>{cs}</div>
                            )
                          }
                        </div>
                      </div>
                      {
                        item.gp ?
                          <div className='spdataGPBlock'>
                            <img src={Check} className='spdataCheckIcon' />
                            <div className='grayText'>По гарантийному письму</div>
                          </div>
                          : null
                      }
                    </div>

                    <div className='spdataSPItemParameterBlock'>
                      {
                        item.specialConditions.length > 0 ?
                          <div>
                            <div className='grayText'>Особые условия</div>
                            {
                              item.specialConditions.length > 0
                                ? item.specialConditions.map((sc, index) =>
                                  <div key={"sc_" + index} className='blackText'>
                                    {sc}
                                  </div>
                                )
                                : <div>-</div>
                            }
                          </div>
                          : <div></div>
                      }
                      {
                        item.multiplicity ?
                          <div className='multiplicityBlock'>
                            <div className='grayText'>Кратность за сезон</div>
                            <div className='blackText'>{item.multiplicity}</div>
                          </div>
                          : ""
                      }
                    </div>

                  </div>

                </div>
              )
            }
          </div>
        </div>
        :
        <div>
          <div id='SPDataDontActiveIKBlock'>
            <img id='SPDataDontActiveIKIcon' src={DontActiveIK} />
            <div id='SPDataDontActiveIKText'>Иммунокарта {data.number} в настоящее время неактивна, срок действия подошел к концу. Оформите новую иммунокарту.  </div>
          </div>
          <a href="https://imkart.ru/documents/chto-delat-pri-ukuse-kleshcha/" target='_blank'>
            <div id='SPDataDontActiveIKButton'>Где получить помощь?</div>
          </a>
        </div>
    }
  </div>;
}
