import React from 'react';
import Logo from './icons/Logo.png'
import './header.scss'
import ArrowBack from './icons/ArrowBack.png'
import User from './icons/User.png'
import logout from './icons/logout.png'

export default function HeaderMobile({mobilePersDataBlock, setMobilePersDataBlock, logOut }) {
    return <div id='HeaderMobile' className='mobileBlock'>
        <div className='headerMobileIconBlock'>
            <img className={'headerMobileIcon' + (mobilePersDataBlock ? "" :" hidden")} onClick={()=>setMobilePersDataBlock(false)} src={ArrowBack} />
        </div>
        <img id='HeaderMobileLogo' src={Logo} />
        <div className='headerMobileIconBlock'>
            <img className={'headerMobileIcon' + (mobilePersDataBlock ? " hidden" :"")} onClick={()=>setMobilePersDataBlock(true)} src={User} />
            <img className={'headerMobileIcon' + (mobilePersDataBlock ? "" :" hidden")} onClick={()=> logOut()} src={logout} />
        </div>
    </div>;
}
